import _ from "lodash";
import moment from "moment-timezone";

export const filterObject = (obj, filter, filterValue) =>
  Object.keys(obj).reduce(function (acc, val) {
    if (obj[val][filter] == filterValue) acc[val] = obj[val];
    return acc;
  }, {});

export const filterObjectByDisable = (obj, filter, filterValue) => {
  return Object.keys(obj).map(function (acc, val) {
    if (filterValue == "all") {
      obj[acc].disable = 0;
      return obj[acc];
    }
    if (obj[acc][filter] == filterValue) {
      obj[acc].disable = 0;
      return obj[acc];
    } else {
      obj[acc].disable = 1;
    }
    return obj[acc];
  }, {});
};

export const filterObjectByDisableMulti = (obj, filter, filterValues) => {
  return Object.keys(obj).map(function (acc, val) {
    //filterValues.includes(2)
    //if (obj[acc][filter] == filterValues) {
    if (filterValues.includes(obj[acc][filter])) {
      obj[acc].is_deleted = 0;
      return obj[acc];
    } else {
      obj[acc].is_deleted = 1;
    }
    return obj[acc];
  }, {});
};

export const convertArrayToObject = (array, key) => {
  return _.mapKeys(array, key);
};

const t = [
  {
    id: 1,
    name: "Floor 2",
  },
  {
    id: 2,
    name: "Floor 2",
  },
];

/* console.log(JSON.stringify(convertArrayToObject(t,'id'))); */

export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const getCountByBayStates = (data) => {
  return data.reduce(function (obj, v) {
    // increment or set the property
    // `(obj[v.status] || 0)` returns the property value if defined
    // or 0 ( since `undefined` is a falsy value
    obj[v.BayStateId] = (obj[v.BayStateId] || 0) + 1;
    // return the updated object
    return obj;
    // set the initial value as an object
  }, {});
};

export const sortBays = (obj, key) => {
  return _.sortBy(obj, key);
};

export const orderBy = (obj, key, dir) => {
  return _.orderBy(obj, [key], [dir]);
};

export const sortFloorsDesc = (obj) => {
  return obj.reverse();
};

export const sortByKey = (obj, key) => {
  return Object.values(obj).sort(function (a, b) {
    return a[key] > b[key] ? 1 : -1;
  });
};

export const filterIntakeObjectByDisable = (obj, filter, filterValue) => {
  return Object.keys(obj).map(function (acc, val) {
    /* if (filterValue == 'all') {
      obj[acc].is_disable = 0;
      return obj[acc];
    } */
    if (!filterValue.includes(obj[acc][filter]) || obj[acc].BayStateId != 1) {
      if (obj[acc].is_disable != 1) {
        obj[acc].is_disable = 1;
      }
      return obj[acc];
    } else {
      obj[acc].is_disable = 0;
    }
    return obj[acc];
  }, {});
};

export const filterObjectWithMultiParam = (obj, filters) => {
  return obj.map((person, i) => {
    if (
      Object.keys(filters).every((filter) => {
        if (typeof filters[filter] == "object") {
          if (filters[filter].length > 0) {
            return filters[filter].includes(person[filter]);
          } else {
            return true;
          }
        } else {
          return filters[filter] == person[filter];
        }
      })
    ) {
      obj[i].is_disable = 0;
    } else {
      obj[i].is_disable = 1;
    }
    return obj[i];
  }, {});
};

export const minutes_to_hhmm = (numberOfMinutes) => {
  //create duration object from moment.duration
  const duration = moment.duration(numberOfMinutes, "minutes");

  //calculate hours
  const hh =
    duration.years() * (365 * 24) +
    duration.months() * (30 * 24) +
    duration.days() * 24 +
    duration.hours();

  //get minutes
  const mm = duration.minutes();

  //return total time in hh:mm format
  return {
    hour: hh,
    minutes: mm,
  };
};

export const groupByKey = (obj, key) => {
  return _.groupBy(obj, key);
};

export const sortByKeySession = (obj, key) => {
  let t = _.sortBy(obj, [key]);
  return t;
};

export const getUniqueKeys = (obj, key) => {
  return _.uniq(_.map(obj, key));
  /*  return _.chain(obj)
   .groupBy(key)
   .toPairs()
   .map(function(currentData){
     return _.zipObject([key, "data"], currentData);
   })
   .value() */
};

export const mergeGroup = (value, key) => {
  var attributes = _.map(value, "bay_type_name");
  var values = _.map(value, "day_part_price");
  return _.chain(attributes)
    .object(values)
    .extend({ day_part_name: key })
    .value();
};

export const timeDiffInMinutes = (endTime) => {
  var startTime = new Date(moment.utc().format("YYYY-MM-DD HH:mm:ss"));
  var endTime = new Date(moment(endTime).format("YYYY-MM-DD HH:mm:ss"));

  var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
  var resultInMinutes = Math.round(difference);

  return resultInMinutes;
};

export const millisToMinutesAndSeconds = (millis) => {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

export const getValueArraysForKey = (obj, key) => {
  return _.map(obj, key);
};

export const getKeyValues = (arr, key) => {
  return _(arr).map(key).uniq().value();
};
export const savedUsers = () => {
  const appName = localStorage.getItem(window.location.hostname);
  if (appName) {
    return JSON.parse(appName);
  }
};

export const checkTokenExpiration = (expTime) => {
  const currentTimestamp = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
  if (currentTimestamp >= expTime) {
    return false;
    // Handle token expiration, e.g., initiate a token refresh or logout
  } else {
    return true;
    // Token is still valid, you can proceed with your application logic
  }
};

const getExpirationTime = (user) => {
  if (user.idTokenClaims && user.idTokenClaims.exp) {
    return checkTokenExpiration(user.idTokenClaims?.exp);
  } else {
    return false;
  }
};

export const checkExpTime = (user) => {
  if (
    window.location.pathname == "/login" ||
    window.location.pathname == "/account-selection"
  ) {
    return true;
  } else {
    const savedUsers = localStorage.getItem(window.location.hostname);
    if (user) {
      const savedUsersJson = JSON.parse(savedUsers);
      const userExists = savedUsersJson.filter((savedUser) => {
        return user?.homeAccountId == savedUser.homeAccountId ? true : false;
      });

      return userExists ? getExpirationTime(user) : false;
    }
  }
};

export const setCookie = (name, value, domain) => {
  var expires = "";

  document.cookie =
    name +
    "=" +
    (value || "") +
    expires +
    ";domain=" +
    domain +
    ";path=/;SameSite=None;secure;";
};
export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};


export const  capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export const   formatNumber = (number, options = {}) => {
  // Default options
  const {
      decimalSeparator = '.',
      thousandSeparator = ',',
      precision = 2,
      currency = false,
      currencySymbol = '$',
      locale = undefined
  } = options;

  // Convert to number if it's not already
  number = Number(number);

  // Check if number is NaN or Infinity
  if (!isFinite(number)) {
      return 'NaN';
  }

  // Format the number
  let formattedNumber;
  if (currency) {
      formattedNumber = number.toLocaleString(locale, {
          style: 'currency',
          currency: currency,
          currencyDisplay: 'symbol'
      });
  } else {
      formattedNumber = number.toFixed(precision);
      if (locale) {
          formattedNumber = Number(formattedNumber).toLocaleString(locale);
      } else {
          formattedNumber = formattedNumber.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              thousandSeparator
          );
      }
  }

  return formattedNumber;
}

export const  getStartAndEndDateOfWeek = (date) => {
  // Parse the input date
  const inputDate = moment(date);

  // Start of the week containing the input date (Sunday)
  const startOfWeek = inputDate.clone().startOf('week');

  // End of the week containing the input date (Saturday)
  const endOfWeek = inputDate.clone().endOf('week');

  // Format dates as needed
  const formattedStartOfWeek = startOfWeek.format('DD MMM YYYY');
  const formattedEndOfWeek = endOfWeek.format('DD MMM YYYY');

  return {
      startOfWeek: formattedStartOfWeek,
      endOfWeek: formattedEndOfWeek
  };
}